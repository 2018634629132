<template>
  <div class="d-flex flex-column">
    <span class="svg-icon svg-icon-10x align-self-center mt-5 mb-8 p-5">
      <inline-svg
        src="/media/svg/illustrations/others/undraw_taken.svg"
      ></inline-svg>
    </span>
    <h1 class="font-weight-bolder text-dark text-center">Forgot Password?</h1>
    <p class="mt-5">
      Don't worry, enter your email and we'll send you the reset password link.
    </p>

    <ForgotPasswordForm class="mt-5"></ForgotPasswordForm>

    <b-button variant="outline-light" class="px-9 py-4 my-3" to="/login">
      {{ $t("BUTTON.CANCEL") }}</b-button
    >
  </div>
</template>

<script>
import ForgotPasswordForm from "@/modules/auth/components/password/ForgotPasswordForm";
export default {
  name: "ForgotPassword",
  components: { ForgotPasswordForm },
};
</script>

<style scoped></style>
