<template>
  <b-form @submit.stop.prevent="onSubmit">
    <!-- begin: Email Input  -->
    <b-form-group
      label="Email"
      label-class="font-size-h6 font-weight-bolder text-dark"
    >
      <b-form-input
        class="form-control-solid"
        size="lg"
        v-model="$v.form.email.$model"
        :state="validateState('email')"
      ></b-form-input>
    </b-form-group>
    <!-- end: Email Input  -->

    <!-- begin: Reset Password Button  -->
    <b-button
      variant="primary"
      class="font-size-h6 font-weight-bolder px-9 py-4 my-3"
      block
      type="submit"
      v-bind:class="{ 'spinner spinner-light spinner-right': isBusy }"
      >{{ $t("LOGIN.FORGOT") }}</b-button
    >
    <!-- end: Reset Password Button  -->
  </b-form>
</template>

<script>
import { validationMixin } from "vuelidate";
import { minLength, required } from "vuelidate/lib/validators";
import swal from "sweetalert";

export default {
  name: "ForgotPasswordForm",
  mixins: [validationMixin],
  data() {
    return {
      form: {
        email: "",
      },
      isBusy: false,
    };
  },
  validations: {
    form: {
      email: {
        required,
        minLength: minLength(3),
      },
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      console.log(this.form.email);
      this.isBusy = true;
      this.$store
        .dispatch("sendResetPassword", this.form.email)
        .then(() => {
          swal({
            title: "Email Sent",
            text: "Please check your email and follow the instructions given",
            icon: "success",
          });
        })
        .catch((reason) => {
          console.error(reason);
          swal({
            title: "Email Not Sent",
            text: "Account does not exist",
            icon: "error",
          });
        })
        .finally(() => {
          this.isBusy = false;
        });
    },
  },
};
</script>

<style scoped></style>
